import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "swiper/css/swiper.min.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import BaiduMap from 'vue-baidu-map';

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "Q1V7UumsHl4bwFYCfDbtQOGcgnSlGMdi",
});

Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI);
Vue.config.productionTip = false

const mixin = {
  methods: {
    freeTrial() {
      this.$root.$children[0].dialogVisible = true;
    }
  },
};

Vue.mixin(mixin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
