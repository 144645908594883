<template>
  <el-container class="app">
    <!-- 头部 -->
    <el-header class="app-header">
      <div class="top">
        <img class="logo" src="./assets/img/common/logo.png" alt="logo" />

        <div class="menu-list">
          <div
            class="menu-item"
            :class="{ menuActive: activeIndex === item.pageName }"
            v-for="(item, index) in menuItemList"
            :key="item.pageName"
            @click="clickMenuItem(item, index)"
          >
            {{ item.title }}
          </div>
        </div>

        <div class="rightBtn">
          <el-button class="login" @click="login()">登录</el-button>
          <el-button class="freeTrial" type="primary" @click="freeTrial"> 免费试用 </el-button>
        </div>
      </div>
      <div class="center">
        <h1 v-html="headerCenterH1Text"></h1>
        <p>制造运营全流程数字化 <br>升级解决方案</p>
        <el-button type="primary" @click="freeTrial">
          免费试用
          <img src="./assets/img/home/lianJieTiaoZhuan-bai.png" alt="跳转" />
        </el-button>
      </div>
      <div class="bottom" v-if="activeIndex === 'Home'">
        <div class="bottom-item" v-for="(item, i) in headerBottomList" :key="i">
          <i :class="['iconfont', item.icon]"></i>
          <p v-html="item.content"></p>
        </div>
      </div>
    </el-header>

    <!-- 中间选项卡内容 -->
    <el-main class="app-main">
      <router-view
        class="app-main-content"
        ref="mainComponent"
        :jumpMaoId="jumpMaoId"
      />
    </el-main>

    <!-- 尾部 -->
    <el-footer class="app-footer">
      <h2>合作伙伴的信赖选择</h2>

      <div class="partnerList">
        <div class="partnerList-item" v-for="item in partnerList" :key="item">
          <img :src="item" alt="" />
        </div>
      </div>

      <div class="tryOut">
        <h3>
          点击一下，免费体验
          <br />
          数千客户信任的工业SaaS软件
        </h3>
        <el-button @click="freeTrial">免费试用申请</el-button>
      </div>

      <div class="bottom">
        <div class="bottom-left">
          <img src="./assets/img/common/logo.png" alt="" />
          <p>
            【硕恒智能科技】
            <br />
            专注为中小离散制造企业提供 有深度、低成本的数字化转型 升级解决方案
          </p>

          <div class="phone">
            <span> 客服热线 </span>
            <br />
            <span class="phone"> 025-5832-5285 </span>
          </div>
        </div>
        <div class="bottom-right">
          <div class="list">
            <div class="list-item">
              <h4>联系我们</h4>
              <span class="detail">客服24小时在线</span>
              <img src="./assets/img/common/QRCode.png" alt="" />
            </div>

            <div class="list-item">
              <h4>产品中心</h4>
              <a class="detail" @click="jumpMao('Product', 'chanPinTeXing')"
                >产品特性</a
              >
              <a class="detail" @click="jumpMao('Product', 'chanPinJIaGou')">产品架构</a>
              <a class="detail" @click="jumpMao('Product', 'chanPinGongNeng')"
                >产品功能</a
              >
            </div>

            <div class="list-item">
              <h4>方案与案例</h4>
              <a class="detail" @click="jumpMao('Solution', 1)">整车制造</a>
              <a
                class="detail"
                @click="jumpMao('Solution', 2)"
                >汽车零部件</a
              >
              <a
                class="detail"
                @click="jumpMao('Solution', 3)"
                >专用装备制造</a
              >
              <a class="detail" @click="jumpMao('Solution', 4)">物流产业</a>
            </div>

            <div class="list-item">
              <h4>关于我们</h4>
              <a class="detail" @click="jumpMao('Home')">公司简介</a>
              <a class="detail" @click="jumpMao('AboutUs')">公司荣誉</a>
              <a class="detail" @click="jumpMao('Cooperation')">合作共赢</a>
              <a class="detail" @click="jumpMao('JoinUs')">加入我们</a>
            </div>
          </div>
        </div>
      </div>
      <div class="number">
        <p>
          Copyright ©2023 &nbsp;江苏硕恒智能科技有限公司 &nbsp;All Rights Reserved.&nbsp;
          <a href="http://beian.miit.gov.cn/">苏ICP备2022043684号</a>&nbsp;
          <a href="http://beian.miit.gov.cn/">苏ICP备2022043684号-1</a>&nbsp;
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32010202011336">
            <img src="./assets/img/home/number.png" alt="">&nbsp;
            苏公网安备 32010202011336号</a>
        </p>
      </div>
    </el-footer>
    <el-backtop :bottom="30">
      <div class="backTop">
        <i class="el-icon-caret-top"></i>
      </div>
    </el-backtop>

    <el-dialog append-to-body :visible.sync="dialogVisible" width="30%" custom-class="freeTraildialog">
      <h3 slot="title">免费试用</h3>

      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item prop="username">
          <el-input v-model="ruleForm.username" placeholder="联系人"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="ruleForm.phone" placeholder="联系电话"></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="ruleForm.email" placeholder="邮箱"></el-input>
        </el-form-item>
        <el-form-item prop="companyName">
          <el-input
            v-model="ruleForm.companyName"
            placeholder="公司名称"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="freeTrialConfirm"
          >提 交</el-button
        >
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
  // 验证手机号
let checkPhone = (rule, value, callback) => {
  if (!value) {
          return callback(new Error("手机号不能为空"));
  } else {
      const reg = /^1[3-9][0-9]\d{8}$/;
      // 这里定义的reg变量没有加引号，所以是一种特殊的数据类型，即正则，可以直接用test()方法
      if (reg.test(value)) {
              callback();
      } else {
              // 具体错误信息没有提示
        return callback(new Error("请输入正确的手机号"));
      }
  }
};

export default {
  data() {
    return {
      ruleForm: {
        username: "",
        phone: "",
        email: "",
        companyName: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        phone: [
          {required: true, message:'请输入联系电话', trigger: 'blur'},
          { min: 11, message: "手机号不足11位", trigger: "blur" },
          { validator: checkPhone, trigger: "change" }
        ],
        email: [
          {required: true, message:'请输入邮箱', trigger: 'blur'},
          {
            pattern:
              "^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(?:\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$",
            message: "邮箱格式不正确",
            trigger: "change",
          },
        ],
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
      },
      dialogVisible: false,
      jumpMaoId: "",
      activeIndex: "",
      menuItemList: [],
      headerBottomList: [
        {
          icon: "icon-yizhanshi",
          content: "一站式<br/>制造运营数字解决方案",
        },
        {
          icon: "icon-gongnengqiangda",
          content: "适用业务场景多、<br/>功能强大、配置灵活",
        },
        {
          icon: "icon-zhongduanzaixianshengji",
          content: "助力企业低成本<br/>实现数字化转型升级",
        },
      ],
      partnerList: [],
      headerCenterH1Text: "",
    };
  },
  watch: {
    $route(newV) {
      console.log("$route", newV.name);
      // 解决页面刷新bug
      this.$router.push(newV.path);
      this.activeIndex = newV.name || "Home";

      this.changeHeaderBgc(newV.meta.index || 0);
      this.clickMenuItem(newV.meta.menuItem, newV.meta.index);
    },
  },
  created() {
    console.log("router before", this.$router.getRoutes());
    this.initmenuItemList();
    this.buildRouter();
    this.clickMenuItem(this.menuItemList[0], 0);
    this.initPartnerList();
    console.log("router after", this.$router.getRoutes());
  },
  mounted() {
    this.initHeaderBGImage();
  },
  methods: {
    // 免费试用 弹框 提交
    freeTrialConfirm(){
      this.$refs.ruleForm.validate(valid => {
        if(valid){
          this.$message.success("提交成功！")
          this.$refs.ruleForm.resetFields()
          this.dialogVisible = false
        }
      })
    },
    // 登录
    login() {
      window.open("http://www.myeicp.com:28008/#/");
    },
    // 修改背景图
    changeHeaderBgc(index) {
      const headerBGImageDiv = document.querySelector(".app-headerBgImg");
      if (!headerBGImageDiv) return;
      headerBGImageDiv.style.transform = `translateX(-${index * 100}vw)`;
    },
    // 切换菜单事件
    clickMenuItem(item, index) {
      if (!item) {
        return;
      }
      const headerCenter = document.querySelector(".app-header .center");
      this.headerCenterH1Text = item.headerCenterH1Text;
      if (headerCenter) {
        if (item.pageName !== "Home") {
          headerCenter.classList.add("notHome");
        } else {
          headerCenter.classList.remove("notHome");
        }
      }

      this.$router.push({ name: item.pageName });

      this.changeHeaderBgc(index);
    },
    // 初始化 顶部菜单
    initmenuItemList() {
      this.menuItemList = [
        {
          title: "首页",
          pageName: "Home",
          headerCenterH1Text: "硕恒<br />智能科技",
        },
        {
          title: "产品",
          pageName: "Product",
          headerCenterH1Text: "工厂数字化升级？<br />一站式解决方案就在这里！",
        },
        {
          title: "方案与案列",
          pageName: "Solution",
          headerCenterH1Text:
            "注重行业聚焦，业务深挖，扎根离散型生产企业<br />选我们就是选择了专业！",
        },
        {
          title: "关于我们",
          pageName: "AboutUs",
          headerCenterH1Text:
            "备受行业认可的<br>工业互联网应用科技公司",
        },
        {
          title: "合作共赢",
          headerCenterH1Text: "欢迎成为<br />我们的合作伙伴",
          pageName: "Cooperation",
        },
        {
          title: "加入我们",
          headerCenterH1Text: "招贤纳士",
          pageName: "JoinUs",
        },
      ];

      this.activeIndex = this.menuItemList[0].pageName;
    },
    // 初始化表头图片
    initHeaderBGImage() {
      const modulesFiles = require.context(
        "./assets/img/home",
        true,
        /home\d\.png$/
      );

      const bgImgList = modulesFiles.keys().map((item) => {
        return require("./assets/img/home/" + item.replace("./", ""));
      });

      let div = null;
      const headerDom = document.querySelector(".app-header");
      const divDom = document.createElement("div");
      divDom.className = "app-headerBgImg";
      const list = [];
      bgImgList.forEach((url, index) => {
        div = document.createElement("div");
        // div.style.height='70vh'
        // div.style.width='100vw'
        div.style.backgroundImage=`url(${url})`
        div.className = "app-headerBgImg-item";

        list.push(div);
      });
      divDom.append(...list);
      headerDom.appendChild(divDom);
      console.log("initHeaderBGImage", bgImgList);
    },

    // 跳转到指定页面的指定锚点
    jumpMao(name, jumpMaoId) {
      this.jumpMaoId = jumpMaoId;
      
      this.$router.push({ name });

      if (!jumpMaoId) {
        document.body.scrollIntoView({ behavior: "smooth" });
      }

      this.$nextTick(() => {
        if (this.$route.name === name && jumpMaoId) {
          if (name == 'Solution') {
            const element = document.querySelector(`#select`);
            
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }else {
            const element = document.querySelector(`#${this.jumpMaoId}`);
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }
        }
      })
    },

    // 初始化合作伙伴列表
    initPartnerList() {
      const modulesFiles = require.context(
        "./assets/img/partnerListLogo",
        true
      );
      this.partnerList = modulesFiles.keys().map((item) => {
        return require("./assets/img/partnerListLogo/" +
          item.replace("./", ""));
      });
    },

    // 构建动态路由
    buildRouter() {
      const routes = this.menuItemList.map((item, index) => ({
        path: `/${item.pageName}`,
        name: item.pageName,
        meta: {
          menuItem: item,
          index: index,
        },
        component: () => import(`@/views/${item.pageName}`),
      }));
      // 去除首页
      routes.shift();
      console.log("routes", routes);

      routes.forEach((item) => {
        this.$router.addRoute(item);
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/globalScss";

:root {
  --product-item-imgDesc-fontSize: 16px;
  --product-item-imgDesc-bottom: -12%;
}

html {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .app-headerBgImg {
    position: absolute;
    display: flex;
    left: 0;
    height: 70vh;
    transition: 1s;
    // .app-headerBgImg-item{
    &-item {
      width: 100vw;
      background-size: 100% 100%;
      height:85vh;
    }
    
  }

  .freeTraildialog {
    border-radius: 8px;
    .el-input {
      position: relative;
      
      &::after {
        content: "*";
        color: red;
        position: absolute;
        right: -10px;
        top: -10px;
      }
    }

    .el-dialog__header{
      h3{
        font-size: 32px;
      }
    }
    .el-dialog__body{
      padding: 20px 35px;
    }
    .el-dialog__footer{
      padding: 0 45px 20px;
      .el-button{
        width: 100%;
      }
    }
  }
}

body{
  padding: unset!important;
}
</style>
<style lang="scss" scoped>
.app {
  .backTop {
    height: 100%;
    width: 100%;
    background-color: #f2f5f6;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
    text-align: center;
    line-height: 40px;
    color: #1989fa;
    border-radius: 10px;
  }

  // 高度
  &-header {
    height: 85vh !important;
    padding: unset;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: 40px;
        > *:not(.app-headerBgImg) {
      z-index: 1;
    }

    .top {
      height: 15%;
      display: flex;
      padding: 32px 56px 0;
      margin-left: 16px;

      .logo {
      }

      .menu-list {
        color: #fff;
        display: flex;
        flex: 1;
        gap: 0 69px;
        font-size: 18px;
        font-weight: bold;
        padding-left: 120px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;

        .menu-item {
          height: max-content;
          padding-bottom: 16px;
          cursor: pointer;
       

          &.menuActive {
            border-bottom: 2px solid #1890ff;
          }
        }
      }

      .rightBtn {
        .el-button {
          color: #fff;
          padding: 8px 16px;
          border-radius: 3px;
        }

        .login {
          background-color: transparent;
        }
      }
    }

    .center {
      flex: 1;
      color: #fff;
      padding-left: 123px;
      padding-top: 75px;
      text-align: left;
      width: 100%;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;

      h1 {
        font-size: 50px;
        line-height: 80px;
        font-weight: 400;
      }

      p {
        margin-top: 25px;
        font-size: 28px;
        line-height: 1.7;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
      }

      .el-button {
        margin-top: 45px;
        font-size: 22px;
        border-radius: 3px;

        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }
      }

      &.notHome {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.8;
        justify-content: space-around;
        h1 {
          text-align: center;
        }
        p {
          display: none;
        }
        .el-button {
          width: fit-content;
          height: fit-content;
          margin-top: 0.22222vw;
        }
      }
    }

    .bottom {
      height: 18%;
      display: flex;
      width: 85%;
      position: absolute;
      bottom: -9%;
      line-height: 2;
      border-radius: 7px;
      border: 7px solid #fff;
      box-shadow: 0 3px 4px 0px rgba(0,0,0,.2);
      justify-content: center;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      line-height: 2;
      &-item {
        flex-grow: 1;
        // border: 1px dashed #c9c9c9;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        background-color: #fff;

        .iconfont {
          font-size: 48px;
        }

        p {
          text-align: left;
          margin-left: 30px;
          font-size: 20px;
          font-weight: 500;
        }

        &:nth-child(2){
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            width: 1px;
            height: 60%;
            background-color: #595959;
          }
          &::after {
            content: '';
            position: absolute;
            right: 0;
            width: 1px;
            height: 60%;
            background-color: #595959;

          }
        }
      }
    }
  }

  &-main {
    padding: unset;
    overflow: hidden;
    background-color: #f9f9f9;

    .app-main-content {
      padding-top: 80px;
    }
  }

  &-footer {
    padding: unset;
    height: unset !important;

    h2 {
      margin-top: 80px;
    }

    .partnerList {
      padding: 60px 131px;
      display: grid;
      grid-gap: 10px 13px;
      grid-template-columns: repeat(auto-fill, 24%);
      grid-template-rows: repeat(5, 1fr);

      &-item {
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
      }
    }

    .tryOut {
      padding: 30px 120px 36px 113px;
      background: url("assets/img/home/footerBackground.png") center/cover
        no-repeat;
      display: flex;
      justify-content: space-between;

      h3 {
        text-align: left;
        line-height: 35px;
        color: #fff;
      }

      .el-button {
        background-color: transparent;
        color: #fff;
        border: 1px solid #ffffff;
        padding: 10px 20px;
        height: max-content;
        margin: auto 0;
      }
    }

    .bottom {
      background: #000000;
      padding: 80px 142px 50px 80px;
      color: #fff;
      display: flex;

      &-left {
        width: 234px;
        text-align: left;

        img {
          width: 100%;
        }

        p {
          margin-top: 40px;
          line-height: 25px;
        }

        .phone {
          margin-top: 20vh;

          .phone {
            font-size: 30px;
            color: #1890ff;
            margin-top: 10px;
          }
        }
      }

      &-right {
        margin-top: 40px;
        flex: 1;

        .list {
          display: flex;
          justify-content: space-around;

          &-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h4 {
              font-size: 20px;
            }

            .detail {
              margin-top: 20px;
              color: #B0C0C5;
              cursor: pointer;

              &:nth-of-type(1) {
                margin-top: 40px;
              }

              &:hover {
                text-decoration: unset;
                color: #409eff;
              }
            }

            img {
              margin-top: 20px;
              width: 90px;
            }
          }
        }
      }
    }
  }

  
}
.number {
  background-color: black;
  color: white;
  margin-top: -20px;
  height: 35px;
}
.number a {
  cursor: pointer;
  color: white;
  text-decoration: none;
}
</style>
